<template>
    <div v-if="promotions">
        <!-- Cart Drawer here -->

        <!-- Page Container here  -->
        <div id="page-container" :class="{ bgcolor1: useBGColor1 }">
            <!-- Header -->
            <!-- No Header for Verify Payment Page -->
            <header-comp v-if="!isVerifyPaymentPage && !useHeaderV2" id="printing" />
            <header-v2-comp v-else-if="useHeaderV2" :showonly="showonly" id="printing" />

            <!-- Initiate all components -->
            <router-view />
            
            <!-- Footer -->
            <!-- No footer for Checkout, Verify Payment & Response Page -->
            <footer-comp :topfooter="true" v-if="!isCheckoutPage && !isVerifyPaymentPage && !isResponsePaymentPage && !useFooterV2" />
            <footer-v2-comp v-else-if="useFooterV2" :showonly="showonly" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import HeaderComp from '../../containers/Header'
import HeaderV2Comp from '../../containers/HeaderV2'
import FooterComp from '../../containers/Footer'
import FooterV2Comp from '../../containers/FooterV2'
export default {
    components: {
        HeaderComp,
        HeaderV2Comp,
        FooterComp,
        FooterV2Comp
    },
    data(){
        return {
            isCheckoutPage: false,
            isVerifyPaymentPage: false,
            isResponsePaymentPage: false,
            useHeaderV2: false,
            useFooterV2: false,
            useBGColor1: false,
            showonly: null
        }
    },
    watch: {
        $route(to, from){
            if(to.path != from.path) this.checkPath()
        }
    },
    computed: {
        ...mapGetters(['promotions']),
    },
    methods:{
        ...mapMutations(['resyncPromo']),
        checkPath(){
            if(this.$route.path == '/' || 
               this.$route.path.includes('/store/cart') || 
               this.$route.path.includes('/home-nursing/booking') ||
               this.$route.path.includes('/e-prescription') || 
               this.$route.path.includes('/clinic-appointment') || 
               this.$route.path.includes('/ambulance-and-paramedic')) { //check whether to use headerv2 & footerv2 or not
                this.useHeaderV2 = true
                this.useFooterV2 = true
                if(this.$route.path.includes('/home-nursing/booking')) this.useBGColor1 = true
                else this.useBGColor1 = false

                if(this.$route.query.showonly) this.showonly = this.$route.query.showonly
            } 
            else if(this.$route.path == '/checkout') this.isCheckoutPage = true
            else if(this.$route.path == '/checkout/redirect_verify') this.isVerifyPaymentPage = true
            else if(this.$route.path == '/checkout/response') this.isResponsePaymentPage = true
            else if(this.$route.path == '/info/about-nursing') this.useFooterV2 = true
            else {
                this.useHeaderV2 = false
                this.useFooterV2 = false
                this.isCheckoutPage = false
                this.isVerifyPaymentPage = false
                this.isResponsePaymentPage = false
                this.useBGColor1 = false
            }
        }
    },
    created(){
        this.resyncPromo() //sync online store promotions
    },
    mounted(){
        this.checkPath()
    }
}
</script>

<style>
.main-container {
    padding: 20px 0px;
}

.main-container .container {
    /* font-family: "Karla",arial,sans-serif; */
    font-size: 15px;
}

.main-container .container h1 {
    letter-spacing: 0.2em;
    /*font-family: "Roboto Slab",serif;*/
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    font-size: 1.71429em;
}

.bgcolor1 {
    background-color: #F2F3F7;
}
</style>